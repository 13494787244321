export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Shin Sonic",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://play.unity.com/en/studio?copy-game=shin-sonic-4",
    domain: "shin-sonic.com",
    gaId: "G-KLMW63BZ2X",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
